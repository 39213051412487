<template>
  <div class="app-card">
    <a-popover title="批量设置维修保费比" v-if="$root.$data.privateState.action.includes('/AssessDamageOrder/ResetDamageVaPremiumByExcel') ||
          ['SuperAdmin', 'Admin'].includes($root.$data.privateState.role)
        ">
        <template slot="content">
          <a-row :gutter="[8, 24]">
            <a-col><a :href="`${$appBaseUrl}/excelTmp/快安维修保费比设置导入模板.xlsx`"  target="_blank" download>快安维修保费比设置导入模板</a></a-col>
          </a-row>
        </template>
        <a-upload
          name="file"
          :file-list="uploadList"
          :action="fileUrl"
          :headers="fileUploadHeaders"
          :multiple="false"
          @change="handleChangeFile"
        >
          <a-button icon="cloud-upload" type="primary">批量设置维修保费比</a-button>
        </a-upload>
    </a-popover>

    <div style="padding-top:20px;">
      <a-row :gutter="[16, 16]">
        <a-col :span="6" v-for="it of chartOption" :key="it.key">
          <a-card :title="it.name" style="width: 100%">
            <p>预估维修保费比：{{it.estimatedRate}}%</p>
            <p>实际维修保费比：{{it.rate}}%</p>
          </a-card>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import { AssessDamageOrder } from '@/api'


export default {
  name: "Dashboard",
  mounted() {
    this.fileUrl = `${this.$appBaseUrl}${AssessDamageOrder}/ResetDamageVsPremiumByExcel`
    this.fileUploadHeaders = {
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }
    this.getDamageVsPremium()
  },
  data() {
    return {
      selectPicker: [],
      chartOption:[],
      // 导入
      uploadList: [],
      fileUrl: '',
      fileUploadHeaders: {},
    };
  },
  methods: {
    async getDamageVsPremium() {
      
      const apiRes = await this.$axios.get(`${AssessDamageOrder}/GetDamageVsPremium`)
        .catch(e => {
          return {
            status: false,
            message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
          }
        })
      if (!apiRes.status) {
        this.$message.error(apiRes.message);
        this.sending = false;
        return null;
      }
      const chartOption = []
      for (const it of apiRes.data) {
        let premium = 0;
        let damageAmount = 0;
        let estimatedDamageAmount = 0;

        if (it.rate) {
          damageAmount = it.rate;
          premium = 100;
        }
        if (it.estimatedRate) {
          estimatedDamageAmount = it.estimatedRate
        }
        premium += it.premium
        damageAmount += it.damageAmount
        estimatedDamageAmount += it.estimatedDamageAmount

        chartOption.push({
          key: it.lossRateUuid,
          name: it.key,
          rate: premium <= 0 ? "无保费" : (damageAmount / premium).toFixed(2),
          estimatedRate: premium <= 0 ? "无保费" : (estimatedDamageAmount / premium).toFixed(2),
        })
      }
      this.$set(this, 'chartOption', chartOption)
    },
    handleChangeFile (info) {
      this.uploadList = [info.fileList[info.fileList.length - 1]]
      if (info.file.status === 'error') {
        this.$message.error('上传文件失败，请尝试重新登录');
        return
      } else if (info.file.status === 'done') {
        this.errorList = []
        if (!info.file.response.status) {
          this.$message.error(info.file.response.message);
          if (info.file.response.data) {
            this.errorList = info.file.response.data
            this.showErrorModal = true
          }
          return;
        }
          this.$confirm({
            title: '导入数据成功',
            content: `维修保养比将进行重新计算`,
            onOk() {
              // _this.fetch();
            },
            onCancel() {
              // _this.fetch();
            },
          });
        
      }
    },
  }
};
</script>

<style scoped>
.chart {
  height: 400px;
}
</style>